import React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import Seo from '../components/seo'
import Layout from '../components/layout'
import PuppyCard from '../components/puppyCard'

const Puppies = () => (
  <Layout>
    <Seo title="Puppies" />
    <article>
      <h1>Puppies</h1>
      <h2>Young puppies</h2>
      <p>We currently have the following young puppies available.</p>
      <PuppyCard
        name="Purebred GSD litter"
        dob="21st December 2024"
        sex="5 Male, 2 Female"
        breed="German Shepherd Dog"
        available="Late February"
        price="$1500"
      >
        <p>
          We have a gorgeous litter of 7 GSD puppies born December 21st, 2024.
          There are 2 females and 5 males with a mix of coat lengths and
          colours.
        </p>
        <p>
          All puppies come with a puppy pack (incl. food starter pack) and come
          wormed, vaccinated and microchipped and receive a thorough veterinary
          health check.
        </p>
        <p>Update: 2 puppies now sold, 5 still available.</p>
        <p>
          Please <Link to="contact">contact us</Link> for more information.
        </p>
      </PuppyCard>

      <h2>Older pups and young dogs</h2>
      <p>
        We also have some beautiful older pups and young dogs available that
        have a lot of the hard work done. While almost fully mature they are
        still very much playful puppies with more growing and filling out to do
        and are ready to settle into their perfect forever homes!
      </p>
      <p>
        If you are interested in adopting one of our beauties please{' '}
        <Link to="contact">Contact us</Link>.
      </p>
    </article>
  </Layout>
)

export default Puppies
